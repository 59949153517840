@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

pre {
  font: inherit;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.bg-background-end-user-license-agreement {
  background-color: #f0f0f0;
}

.scroll-outer-container {
  max-width: 70rem;
  margin: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 40rem;
}

.end-user-license-agreement {
  background-color: white;
  color: #231f20;
  max-width: 70rem;
  margin: auto;
  font-family: 'Work Sans', sans-serif !important;

  padding: 30px;
}

h1 {
  color: #293f96 !important;
}

.button-container {
  background-color: white;
  width: 70rem;
  margin: auto;
  padding: 30px;
}

.image-container {
  max-width: 70rem;
  margin: auto;
  padding: 30px;
}

.bg-background-100 {
  height: 100%;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #ffffff !important;
  color: #231f20 !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15) !important;
  box-sizing: border-box;
}

.btn-secondary {
  background-color: #fff !important;
  border: 1px solid #483d3f !important;
  color: #231f20 !important;
}

.btn-secondary:focus,
.btn-secondary:visited,
.btn.btn-secondary:focus,
.btn.btn-secondary:visited {
  border: 1px solid #483d3f !important;
}

.btn-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-secondary:not(:disabled):not(.disabled):hover {
  border: 1px solid #483d3f !important;
  background-color: #f0f0f0 !important;
}

img {
  width: 150px;
}

h5 {
  font-weight: bold;
}

// stolen from here: https://stackoverflow.com/a/26245056

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table-row;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.6em;
  font-weight: bold;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, '.') ' ';
}

.sublist-items {
  padding-left: 38px;
}

.eula-table {
  margin-left: auto;
  margin-right: auto;
  border: 0.75pt solid #000000;
  border-collapse: collapse;
}

.eula-table td,
.eula-table th {
  border: 0.75pt solid #000000;
  text-align: center;
  width: 300px;
}

.eula-table-align-left {
  text-align: left !important;
  padding-left: 10px;
}
